:root {
  /* GLOBAL */
  --system-black: #272b2f;
  --system-sceondary-gray-900: #485059;
  --system-sceondary-gray-800: #737c85;
  --system-sceondary-gray-600: #a6abb1;
  --system-sceondary-gray-400: #d3d8de;
  --system-sceondary-gray-500: #c1c7cf;
  --system-sceondary-gray-300: #e2e5ea;
  --system-sceondary-gray-100: #f5f6f7;
  --system-white: #ffffff;
  --system-primary-orange-500: #fa6600;
  --brand-deepyellow-200: #ffd78a;
  --system-primary-orange-400: #ff8f4c;
  --brand-orangeember-400: #ff8f4c;
  --system-sceondary-gray-700: #8c959d;
  --system-primary-orange-300: #ffaf7f;
  --system-primary-orange-100: #ffe0cc;
  --system-sceondary-gray-200: #f1f2f5;
  --system-dimmed: rgba(0 0 0 / 0.5);
  --system-primary-orange-600: #e15a00;
  --system-primary-orange-200: #ffc299;
  --system-primary-orange-700: #bf4c00;
  --system-primary-orange-800: #9e3f00;
  --system-primary-orange-900: #803300;

  /* TOKEN */
  --text-primary: var(--system-black);
  --text-inverse: var(--system-white);
  --text-secondary: var(--system-sceondary-gray-900);
  --text-neutral-01: var(--system-sceondary-gray-600);
  --border-secondary: var(--system-black);
  --border-primary: var(--system-white);
  --spacing-300: 24px;
  --spacing-300-rem: 1.5rem;
  --spacing-100: 8px;
  --spacing-100-rem: 0.5rem;
  --spacing-125: 10px;
  --spacing-125-rem: 0.625rem;
  --spacing-050: 4px;
  --spacing-050-rem: 0.25rem;
  --control-primary: var(--system-primary-orange-500);
  --control-neutral-03: var(--system-sceondary-gray-100);
  --radius-l: 12px;
  --radius-l-rem: 0.75rem;
  --radius-m: 8px;
  --radius-m-rem: 0.5rem;
  --radius-s: 2px;
  --radius-s-rem: 0.125rem;
  --spacing-200: 16px;
  --spacing-200-rem: 1rem;
  --text-body: var(--system-sceondary-gray-800);
  --spacing-375: 30px;
  --spacing-375-rem: 1.875rem;
  --control-secondary: var(--system-sceondary-gray-900);
  --background-primary: var(--system-white);
  --background-secondary: var(--system-sceondary-gray-100);
  --background-dimmed: var(--system-dimmed);
  --spacing-250: 20px;
  --spacing-250-rem: 1.25rem;
  --spacing-0: 0px;
  --spacing-0-rem: 0rem;
  --text-neutral-02: var(--system-sceondary-gray-400);
  --spacing-025: 2px;
  --spacing-025-rem: 0.125rem;
  --spacing-500: 40px;
  --spacing-500-rem: 2.5rem;
  --spacing-600: 48px;
  --spacing-600-rem: 3rem;
  --spacing-400: 32px;
  --spacing-400-rem: 2rem;
  --control-neutral-02: var(--system-sceondary-gray-200);
  --control-neutral: var(--system-white);
  --spacing-150: 12px;
  --spacing-150-rem: 0.75rem;
  --spacing-2000: 160px;
  --spacing-2000-rem: 10rem;
  --spacing-800: 64px;
  --spacing-800-rem: 4rem;
  --spacing-700: 56px;
  --spacing-700-rem: 3.5rem;
  --control-bold2: var(--system-sceondary-gray-700);
  --spacing-1: -1px;
  --spacing-1-rem: -0.0625rem;
  --state-btn-pressed: rgba(0 0 0 / 0.16);
  --state-hover: rgba(0 0 0 / 0.1);
  --state-pressed: rgba(0 0 0 / 0.02);
  --state-disabled: rgba(255 255 255 / 0.6);
  --control-neutral-01: var(--system-sceondary-gray-300);
  --spacing-075: 6px;
  --spacing-075-rem: 0.375rem;
  --spacing-175: 14px;
  --spacing-175-rem: 0.875rem;
  --radius-xl: 16px;
  --radius-xl-rem: 1rem;
  --text-body2: var(--system-sceondary-gray-700);
  --control-bold: var(--system-black);

  /* TYPE */
  --title1-family: "HanwhaGothic";
  --title1-size: 26px;
  --title1-size-rem: 1.625rem;
  --title1-style: normal;
  --title1-line-height: 150%;
  --title2-family: "HanwhaGothic";
  --title2-size: 22px;
  --title2-size-rem: 1.375rem;
  --title2-style: normal;
  --title2-line-height: 150%;
  --title3-family: "HanwhaGothic";
  --title3-size: 20px;
  --title3-size-rem: 1.25rem;
  --title3-style: normal;
  --title3-line-height: 150%;
  --title4-family: "HanwhaGothic";
  --title4-size: 18px;
  --title4-size-rem: 1.125rem;
  --title4-style: normal;
  --title4-line-height: 150%;
  --title5-family: "HanwhaGothic";
  --title5-size: 16px;
  --title5-size-rem: 1rem;
  --title5-style: normal;
  --title5-line-height: 150%;
  --subtitle1-family: "HanwhaGothic";
  --subtitle1-size: 18px;
  --subtitle1-size-rem: 1.125rem;
  --subtitle1-style: normal;
  --subtitle1-line-height: 150%;
  --subtitle2-family: "HanwhaGothic";
  --subtitle2-size: 16px;
  --subtitle2-size-rem: 1rem;
  --subtitle2-style: normal;
  --subtitle2-line-height: 150%;
  --body1-family: "HanwhaGothic";
  --body1-size: 16px;
  --body1-size-rem: 1rem;
  --body1-style: normal;
  --body1-line-height: 150%;
  --body2-family: "HanwhaGothic";
  --body2-size: 14px;
  --body2-size-rem: 0.875rem;
  --body2-style: normal;
  --body2-line-height: 150%;
  --link_large-family: "HanwhaGothic";
  --link_large-size: 16px;
  --link_large-size-rem: 1rem;
  --link_large-style: normal;
  --link_large-line-height: 150%;
  --link_medium-family: "HanwhaGothic";
  --link_medium-size: 14px;
  --link_medium-size-rem: 0.875rem;
  --link_medium-style: normal;
  --link_medium-line-height: 150%;
  --link_small-family: "HanwhaGothic";
  --link_small-size: 12px;
  --link_small-size-rem: 0.75rem;
  --link_small-style: normal;
  --link_small-line-height: 150%;
  --caption-family: "HanwhaGothic";
  --caption-size: 12px;
  --caption-size-rem: 0.75rem;
  --caption-style: normal;
  --caption-line-height: 140%;
}

* {-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; font-family: "HanwhaGothic";}
html, body { height: 100%; }
#root { height:100%; }