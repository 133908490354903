* {
    padding: 0;
    margin: 0;
    vertical-align: baseline;
    box-sizing: border-box;
}

.wrap {
    display: flex;
    flex-direction: column;
    height:100%;
}

.tip_bg {
    position: relative;
    background-color: #F5F6F7;
}

.tip_gradient {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(245, 246, 247, 0), #e9ebf0);
    z-index: 0;
}

.tip_bg .cont_box {
    z-index: 1;
}

.cont_box {
    display: flex;
    flex-direction: column;
    height:100%;
}

.cont_body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.btm_box {
    display: flex;
    flex-direction: column;
}

.ContWrap {
	margin: 30px 20px 12px;
	position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.ContWrap_block {
    display: block;
}

.step1_img{
    display: block;
    margin: 57px auto 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.info_img{
    display: block;
    margin:0 auto 16px;
}

.mask {
	position: absolute;
	width: 300px;
	height: 200px;
	top: 49%;
	left: 30%;
	background: rgba(0, 0, 0, 0.3);
	box-shadow: 0px 0px 0px 99999px rgba(0, 0, 0, 0.6);
  }

.noscroll { overflow: hidden;}